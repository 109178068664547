import { LargeImage } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';

import contentCache from './contentCache.json';
import { marginContainer, wrap } from './LargeImageModule.module.css';

export default function LargeImageModule({
  className,
  data,
  contentKey
}) {
  const {
    hasMarginBottom,
    imageAlt,
    imageSrcFluid
  } = data || contentCache[contentKey];

  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      <LargeImage
        className={className}
        imageAlt={imageAlt}
        imageSrcFluid={imageSrcFluid.fluid}
      />
    </div>
  );
}

export const LargeImageDataPropsObject = {
  hasMarginBottom : PropTypes.bool,
  imageAlt        : PropTypes.string,
  imageSrcFluid   : PropTypes.shape({
    aspectRatio : PropTypes.number,
    sizes       : PropTypes.string,
    src         : PropTypes.string,
    srcSet      : PropTypes.string,
    srcSetWebp  : PropTypes.string,
    srcWebp     : PropTypes.string
  }).isRequired
};

LargeImageModule.propTypes = {
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(LargeImageDataPropsObject), (props) => !props.contentKey)
};

LargeImageModule.defaultProps = {
  className  : null,
  contentKey : null,
  data       : null
};

export const LargeImageModuleData = graphql`
  fragment LargeImageModuleData on ContentfulComponentLargeImage {
    hasMarginBottom
    imageAlt
    imageSrcFluid {
      fluid(maxWidth: 720) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
