import { VideoWithNumberedSteps } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';

import contentCache from './contentCache.json';
import { marginContainer, wrap } from './VideoWithNumberedStepsModule.module.css';

export default function VideoWithNumberedStepsModule({
  blobs,
  className,
  data,
  contentKey
}) {
  const {
    ctaCopy,
    ctaUrl,
    ctaTrackingClass,
    hasMarginBottom,
    mobileCtaTrackingClass,
    mobileVideoTrackingClass,
    stepOneTitle,
    stepOneCopy,
    stepTwoTitle,
    stepTwoCopy,
    stepThreeTitle,
    stepThreeCopy,
    videoMp4,
    videoPoster,
    videoMp4Mobile,
    videoPosterMobile,
    videoTrackingClass
  } = data || contentCache[contentKey];

  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });
  return (
    <div className={classes}>
      {blobs}
      <VideoWithNumberedSteps
        className={className}
        ctaCopy={ctaCopy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={ctaUrl}
        mobileCtaTrackingClass={mobileCtaTrackingClass}
        mobileVideoTrackingClass={mobileVideoTrackingClass}
        stepOneCopy={stepOneCopy.stepOneCopy}
        stepOneTitle={stepOneTitle}
        stepThreeCopy={stepThreeCopy.stepThreeCopy}
        stepThreeTitle={stepThreeTitle}
        stepTwoCopy={stepTwoCopy.stepTwoCopy}
        stepTwoTitle={stepTwoTitle}
        videoMp4={videoMp4}
        videoMp4Mobile={videoMp4Mobile}
        videoPoster={videoPoster && videoPoster.fixed.srcWebp}
        videoPosterMobile={videoPosterMobile && videoPosterMobile.fixed.srcWebp}
        videoTrackingClass={videoTrackingClass}
      />
    </div>
  );
}

export const VideoWithNumberedStepsDataPropsObject = {
  className                : PropTypes.string,
  ctaCopy                  : PropTypes.string,
  ctaTrackingClass         : PropTypes.string,
  ctaUrl                   : PropTypes.string,
  hasMarginBottom          : PropTypes.bool,
  mobileCtaTrackingClass   : PropTypes.string,
  mobileVideoTrackingClass : PropTypes.string,
  stepOneCopy              : PropTypes.shape({
    stepOneCopy : PropTypes.string
  }),
  stepOneTitle  : PropTypes.string,
  stepThreeCopy : PropTypes.shape({
    stepThreeCopy : PropTypes.string
  }),
  stepThreeTitle : PropTypes.string,
  stepTwoCopy    : PropTypes.shape({
    stepTwoCopy : PropTypes.string
  }),
  stepTwoTitle   : PropTypes.string,
  videoMp4       : PropTypes.string,
  videoMp4Mobile : PropTypes.string,
  videoPoster    : PropTypes.shape({
    fixed : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoPosterMobile : PropTypes.shape({
    fixed : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoTrackingClass : PropTypes.string
};

VideoWithNumberedStepsModule.propTypes = {
  blobs      : PropTypes.node,
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(VideoWithNumberedStepsDataPropsObject), (props) => !props.contentKey)
};

VideoWithNumberedStepsModule.defaultProps = {
  blobs      : null,
  className  : null,
  contentKey : null,
  data       : null
};

export const VideoWithNumberedStepsModuleData = graphql`
  fragment VideoWithNumberedStepsModuleData on ContentfulComponentVideoWithNumberedSteps {
    ctaCopy
    ctaUrl
    ctaTrackingClass
    hasMarginBottom
    mobileCtaTrackingClass
    mobileVideoTrackingClass
    stepOneTitle
    stepOneCopy {
      stepOneCopy
    }
    stepTwoTitle
    stepTwoCopy {
      stepTwoCopy
    }
    stepThreeTitle
    stepThreeCopy {
      stepThreeCopy
    }
    videoMp4
    videoMp4Mobile
    videoPoster {
      fixed(quality: 80, width: 816) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    videoPosterMobile {
      fixed(quality: 80, width: 336) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    videoTrackingClass
  }
`;
