import { IconListWithMedia } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import CopyLockupModule, { CopyLockupDataProps } from '../CopyLockupModule';
import {
  defaultClass,
  left,
  marginContainer,
  right
} from './IconListWithMediaModule.module.css';

export default function IconListWithMediaModule({
  className,
  data
}) {
  const {
    align,
    copy,
    copyLockups,
    ctaButtonCopy,
    ctaButtonTrackingClass,
    ctaButtonTrackingClassMobile,
    ctaButtonUrl,
    ctaLinkCopy,
    ctaLinkTrackingClass,
    ctaLinkTrackingClassMobile,
    ctaLinkUrl,
    hasMarginBottom,
    image,
    imageAlt,
    tagline,
    title,
    videoPoster,
    videoUrl
  } = data;

  const classes = classNames(className, {
    [defaultClass]    : !align,
    [left]            : align === 'left',
    [right]           : align === 'right',
    [marginContainer] : hasMarginBottom
  });

  return (
    <IconListWithMedia
      align={align}
      className={classes}
      ctaCopy={ctaButtonCopy}
      ctaLinkCopy={ctaLinkCopy}
      ctaLinkTrackingClass={ctaLinkTrackingClass}
      ctaLinkTrackingClassMobile={ctaLinkTrackingClassMobile}
      ctaLinkUrl={ctaLinkUrl}
      ctaTrackingClass={ctaButtonTrackingClass}
      ctaTrackingClassMobile={ctaButtonTrackingClassMobile}
      ctaUrl={ctaButtonUrl}
      headerBody={copy && copy.copy}
      imageAlt={imageAlt}
      imageSrcFluid={image && image.fluid}
      tagline={tagline}
      title={title}
      videoMp4={videoUrl}
      videoPoster={videoPoster && videoPoster.fluid.srcWebp}
    >
      {copyLockups.map((item) => (
        <CopyLockupModule
          key={item.title}
          data={item}
        />
      ))}
    </IconListWithMedia>
  );
}

export const IconListWithMediaDataPropsObject = {
  align : PropTypes.oneOf(['left', 'right', '']),
  copy  : PropTypes.shape({
    copy : PropTypes.string
  }),
  copyLockups                  : PropTypes.arrayOf(CopyLockupDataProps),
  ctaButtonCopy                : PropTypes.string,
  ctaButtonTrackingClass       : PropTypes.string,
  ctaButtonTrackingClassMobile : PropTypes.string,
  ctaButtonUrl                 : PropTypes.string,
  ctaLinkCopy                  : PropTypes.string,
  ctaLinkTrackingClass         : PropTypes.string,
  ctaLinkTrackingClassMobile   : PropTypes.string,
  ctaLinkUrl                   : PropTypes.string,
  hasMarginBottom              : PropTypes.bool,
  image                        : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  imageAlt    : PropTypes.string,
  tagline     : PropTypes.string,
  title       : PropTypes.string,
  videoPoster : PropTypes.shape({
    fluid : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoUrl : PropTypes.string
};

IconListWithMediaModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(IconListWithMediaDataPropsObject).isRequired
};

IconListWithMediaModule.defaultProps = {
  className : null
};

export const IconListWithMediaModuleData = graphql`
  fragment IconListWithMediaModuleData on ContentfulComponentIconListWithMedia {
    align
    copy {
      copy
    }
    copyLockups {
      ...CopyLockupModuleData
    }
    ctaButtonCopy
    ctaButtonTrackingClass
    ctaButtonTrackingClassMobile
    ctaButtonUrl
    ctaLinkCopy
    ctaLinkTrackingClass
    ctaLinkTrackingClassMobile
    ctaLinkUrl
    hasMarginBottom
    image {
      fluid(quality: 80, maxWidth:688) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageAlt
    tagline
    title
    videoPoster {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoUrl
  }
`;
