// extracted by mini-css-extract-plugin
export var base = "VideoWithNumberedStepsModule-module--base--39tTe";
export var rightAlignedContainer = "VideoWithNumberedStepsModule-module--rightAlignedContainer--1hEcM";
export var rightAlignedContainerSmall = "VideoWithNumberedStepsModule-module--rightAlignedContainerSmall--3ulW9";
export var leftAlignedContainer = "VideoWithNumberedStepsModule-module--leftAlignedContainer---_lt_";
export var blogContainer = "VideoWithNumberedStepsModule-module--blogContainer--34WE4 VideoWithNumberedStepsModule-module--base--39tTe";
export var container = "VideoWithNumberedStepsModule-module--container--VHWur VideoWithNumberedStepsModule-module--base--39tTe";
export var largeContainer = "VideoWithNumberedStepsModule-module--largeContainer--tuCXS VideoWithNumberedStepsModule-module--base--39tTe";
export var mobileSmallPadding = "VideoWithNumberedStepsModule-module--mobileSmallPadding--36ZfO";
export var fullWidthMobile = "VideoWithNumberedStepsModule-module--fullWidthMobile--3exXF";
export var gridWithSidebar = "VideoWithNumberedStepsModule-module--gridWithSidebar--1yAnP";
export var noMaxWidth = "VideoWithNumberedStepsModule-module--noMaxWidth--1rRk0";
export var wrap = "VideoWithNumberedStepsModule-module--wrap--HB_uh";
export var marginContainer = "VideoWithNumberedStepsModule-module--marginContainer--2KFxc VideoWithNumberedStepsModule-module--largeContainer--tuCXS VideoWithNumberedStepsModule-module--base--39tTe";