import { Step, StepByStep, useClient } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import requiredIf from 'react-required-if';

import contentCache from './contentCache.json';
import { container, fallback, marginContainer, wrap } from './StepByStepModule.module.css';

const AddPhotoIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/AddPhotoIllustration'));
const CreateIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/CreateIllustration'));
const CustomizeIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/CustomizeIllustration'));
const ImpressIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/ImpressIllustration'));
const LaptopIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/LaptopIllustration'));
const ProduceIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/ProduceIllustration'));

const iconMap = {
  AddPhotoIllustration,
  CreateIllustration,
  CustomizeIllustration,
  ImpressIllustration,
  LaptopIllustration,
  ProduceIllustration
};

const getIllustrationElement = (icon) => {
  const IconElement = iconMap[icon];
  return (
    <IconElement />
  );
};

export default function StepByStepModule({
  blobs,
  className,
  contentKey,
  data
}) {
  const {
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    hasMarginBottom,
    steps,
    subtitle,
    subtitleIllustration,
    title
  } = data || contentCache[contentKey];

  const isClient = useClient();
  const classes = classNames(className, container, wrap, {
    [marginContainer] : hasMarginBottom
  });
  // TODO find another way to NOT wrap this entire block in a useClient check, so that it can still be server side rendered.

  return (
    <div className={classes}>
      {blobs}
      {isClient && (
        <Suspense fallback={<span className={fallback} />}>
          <StepByStep
            className={className}
            ctaCopy={ctaCopy}
            ctaTrackingClass={ctaTrackingClass}
            ctaUrl={ctaUrl}
            subtitle={subtitle}
            subtitleIllustration={subtitleIllustration && getIllustrationElement(subtitleIllustration)}
            title={title}
          >
            {steps.map((item) => (
              <Step
                key={item.title}
                copy={item.copy.copy}
                ctaCopy={item.ctaCopy}
                ctaTrackingClass={item.ctaTrackingClass}
                ctaUrl={item.ctaUrl}
                illustration={item.illustration && getIllustrationElement(item.illustration)}
                tagline={item.tagline}
                title={item.title}
                videoMp4={item.videoUrl}
                videoPoster={item.videoPoster && item.videoPoster.fluid.srcWebp}
              />
            ))}
          </StepByStep>
        </Suspense>
      )}
    </div>
  );
}

export const StepByStepDataPropsObject = {
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  hasMarginBottom  : PropTypes.bool,
  steps            : PropTypes.arrayOf(PropTypes.shape({
    copy : PropTypes.shape({
      copy : PropTypes.string
    }),
    ctaCopy          : PropTypes.string,
    ctaTrackingClass : PropTypes.string,
    ctaUrl           : PropTypes.string,
    illustration     : PropTypes.string,
    tagline          : PropTypes.string,
    title            : PropTypes.string.isRequired,
    videoPoster      : PropTypes.shape({
      fluid : PropTypes.shape({
        srcWebp : PropTypes.string
      })
    }),
    videoUrl : PropTypes.string
  })).isRequired,
  subtitle             : PropTypes.string,
  subtitleIllustration : PropTypes.string,
  title                : PropTypes.string
};

StepByStepModule.propTypes = {
  blobs      : PropTypes.node,
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(StepByStepDataPropsObject), (props) => !props.contentKey)
};

StepByStepModule.defaultProps = {
  blobs      : null,
  className  : null,
  contentKey : null,
  data       : null
};

export const StepByStepModuleData = graphql`
  fragment StepByStepModuleData on ContentfulComponentStepByStep {
    ctaCopy
    ctaTrackingClass
    ctaUrl
    hasMarginBottom
    steps {
      copy {
        copy
      }
      illustration
      tagline
      title
      videoPoster {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoUrl
    }
    subtitle
    subtitleIllustration
    title
  }
`;
