// importing Modules for Universal Layout

import CaseStudyFeaturedContentModule from './CaseStudyFeaturedContentModule';
import CaseStudyHeroModule from './CaseStudyHeroModule';
import CategorizedArticleLibraryModule from './CategorizedArticleLibraryModule';
import CategorizedFeaturedTemplatesModule from './CategorizedFeaturedTemplatesModule';
import CertifiedPartnersListModule from './CertifiedPartnersListModule';
import CommunityModule from './CommunityModule';
import ComparisonChartModule from './ComparisonChartModule';
import CopyBlockModule from './CopyBlockModule';
import CopyLockupListModule from './CopyLockupListModule';
import FaqModule from './FaqModule';
import FeatureCalloutsModule from './FeatureCalloutsModule';
import FeaturedContentsModule from './FeaturedContentsModule';
import FeaturedTemplatesModule from './FeaturedTemplatesModule';
import HeroModule from './HeroModule';
import HowToOrderedListModule from './HowToOrderedListModule';
import IconListWithMediaModule from './IconListWithMediaModule';
import LargeImageModule from './LargeImageModule';
import LegalCopyBlockModule from './LegalCopyBlockModule';
import LegalSidebarMenuModule from './LegalSidebarMenuModule';
import PitchModule from './PitchModule';
import ProTipModule from './ProTipModule';
import SectionFooterModule from './SectionFooterModule';
import SectionHeaderModule from './SectionHeaderModule';
import SocialPlatformCalloutModule from './SocialPlatformCalloutModule';
import StepByStepModule from './StepByStepModule';
import SuccessStoryModule from './SuccessStoryModule';
import TestimonialsModule from './TestimonialsModule';
import TipsListModule from './TipsListModule';
import TipsListTabsModule from './TipsListTabsModule';
import VideoPlayerModule from './VideoPlayerModule';
import VideoWithNumberedStepsModule from './VideoWithNumberedStepsModule';

const ComponentMap = {
  ContentfulComponentCaseStudyFeaturedContent     : CaseStudyFeaturedContentModule,
  ContentfulComponentCaseStudyHero                : CaseStudyHeroModule,
  ContentfulComponentCategorizedArticleLibrary    : CategorizedArticleLibraryModule,
  ContentfulComponentCategorizedFeaturedTemplates : CategorizedFeaturedTemplatesModule,
  ContentfulComponentCertifiedPartnersList        : CertifiedPartnersListModule,
  ContentfulComponentCommunity                    : CommunityModule,
  ContentfulComponentComparisonChart              : ComparisonChartModule,
  ContentfulComponentCopyBlock                    : CopyBlockModule,
  ContentfulComponentCopyLockupList               : CopyLockupListModule,
  ContentfulComponentFrequentlyAskedQuestionsFaq  : FaqModule,
  ContentfulComponentFeatureCallouts              : FeatureCalloutsModule,
  ContentfulComponentFeaturedContents             : FeaturedContentsModule,
  ContentfulComponentFeaturedTemplates            : FeaturedTemplatesModule,
  ContentfulComponentHero                         : HeroModule,
  ContentfulComponentHowToOrderedList             : HowToOrderedListModule,
  ContentfulComponentIconListWithMedia            : IconListWithMediaModule,
  ContentfulComponentLargeImage                   : LargeImageModule,
  ContentfulComponentLegalCopyBlock               : LegalCopyBlockModule,
  ContentfulComponentLegalSidebarMenu             : LegalSidebarMenuModule,
  ContentfulComponentPitch                        : PitchModule,
  ContentfulComponentProTip                       : ProTipModule,
  ContentfulComponentSectionFooter                : SectionFooterModule,
  ContentfulComponentSectionHeader                : SectionHeaderModule,
  ContentfulComponentSocialPlatformCallout        : SocialPlatformCalloutModule,
  ContentfulComponentStepByStep                   : StepByStepModule,
  ContentfulComponentSuccessStory                 : SuccessStoryModule,
  ContentfulComponentTestimonials                 : TestimonialsModule,
  ContentfulComponentTipsList                     : TipsListModule,
  ContentfulComponentTipsListTabs                 : TipsListTabsModule,
  ContentfulComponentVideoPlayer                  : VideoPlayerModule,
  ContentfulComponentVideoWithNumberedSteps       : VideoWithNumberedStepsModule
};

export { ComponentMap as default };
