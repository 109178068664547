import { SectionFooter } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';

import contentCache from './contentCache.json';
import { marginContainer } from './SectionFooterModule.module.css';

export default function SectionFooterModule({
  className,
  data,
  contentKey
}) {
  const {
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    hasMarginBottom,
    title
  } = data || contentCache[contentKey];

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <SectionFooter
      className={classes}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaUrl={ctaUrl}
      title={title}
    />
  );
}

export const SectionFooterDataPropsObject = {
  ctaCopy          : PropTypes.string.isRequired,
  ctaTrackingClass : PropTypes.string.isRequired,
  ctaUrl           : PropTypes.string.isRequired,
  hasMarginBottom  : PropTypes.bool,
  title            : PropTypes.string.isRequired
};

SectionFooterModule.propTypes = {
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(SectionFooterDataPropsObject), (props) => !props.contentKey)
};

SectionFooterModule.defaultProps = {
  className  : null,
  contentKey : null,
  data       : null
};

export const SectionFooterModuleData = graphql`
  fragment SectionFooterModuleData on ContentfulComponentSectionFooter {
    ctaCopy
    ctaTrackingClass
    ctaUrl
    hasMarginBottom
    title
  }
`;
