import { TipsList } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import StyledSectionBackground from '../StyledSectionBackground';
import { listWrap, wrap } from './TipsListModule.module.css';

export default function TipsListModule({
  blobs,
  className,
  data
}) {
  const {
    hasBackgroundColor,
    tips,
    title
  } = data;
  const classes = classNames(className, wrap);
  return (
    <StyledSectionBackground className={classes} hasBackgroundColor={hasBackgroundColor} hasMobileWhiteBackground>
      {blobs}
      <div className={listWrap}>
        {blobs}
        <TipsList
          className={className}
          title={title}
        >
          {tips.childMarkdownRemark.htmlAst}
        </TipsList>
      </div>
    </StyledSectionBackground>
  );
}

export const TipsListDataPropsObject = {
  hasBackgroundColor : PropTypes.bool,
  tips               : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  title : PropTypes.string
};

TipsListModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(TipsListDataPropsObject).isRequired
};

TipsListModule.defaultProps = {
  blobs     : null,
  className : null
};

export const TipsListModuleData = graphql`
  fragment TipsListModuleData on ContentfulComponentTipsList {
    hasBackgroundColor
    tips {
      childMarkdownRemark {
        htmlAst
      }
    }
    title
  }
`;
