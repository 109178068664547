import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ComponentMap from '../../components';
import { AnimotoLayout } from '../../components/AnimotoLayout';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';

export default function MarketingUniversalPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const { hero } = componentsQueryResultToData(components);
  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      {
        components.map((component) => {
          const isLocalCacheComponent = component.__typename === 'ContentfulComponentLocalCache';
          const componentData = isLocalCacheComponent ? null : component;

          let Module = ComponentMap[component.__typename];
          if (isLocalCacheComponent) {
            Module = ComponentMap[component.contentTypeName];
          }

          return <Module key={uniqueId(component.__typename)} contentKey={component.contentKey} data={componentData} />;
        })
      }
    </AnimotoLayout>
  );
}
export const pageQuery = graphql`
  query MarketingUniversalPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;
MarketingUniversalPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.shape({
        __typename : PropTypes.string.isRequired
      })).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
