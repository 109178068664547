// extracted by mini-css-extract-plugin
export var base = "StepByStepModule-module--base--2GsZB";
export var rightAlignedContainer = "StepByStepModule-module--rightAlignedContainer--1j3JZ";
export var rightAlignedContainerSmall = "StepByStepModule-module--rightAlignedContainerSmall--1hJc0";
export var leftAlignedContainer = "StepByStepModule-module--leftAlignedContainer--1f_56";
export var blogContainer = "StepByStepModule-module--blogContainer--XYJax StepByStepModule-module--base--2GsZB";
export var container = "StepByStepModule-module--container--3UJBh StepByStepModule-module--base--2GsZB StepByStepModule-module--container--3UJBh StepByStepModule-module--base--2GsZB StepByStepModule-module--fullWidthMobile--mhB02";
export var largeContainer = "StepByStepModule-module--largeContainer--BBVFF StepByStepModule-module--base--2GsZB";
export var mobileSmallPadding = "StepByStepModule-module--mobileSmallPadding--1GIyc";
export var fullWidthMobile = "StepByStepModule-module--fullWidthMobile--mhB02";
export var gridWithSidebar = "StepByStepModule-module--gridWithSidebar--3UiaG";
export var noMaxWidth = "StepByStepModule-module--noMaxWidth--3eSey";
export var fallback = "StepByStepModule-module--fallback--2-poF";
export var wrap = "StepByStepModule-module--wrap--1gukc";
export var marginContainer = "StepByStepModule-module--marginContainer--O-0NN";