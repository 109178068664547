import { ComparisonChart } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { marginContainer } from './ComparisonChartModule.module.css';

export default function ComparisonChartModule({
  className,
  data
}) {
  const {
    hasMarginBottom,
    headerText,
    plans,
    features,
    featureTypes
  } = data;

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <ComparisonChart
      className={classes}
      features={features}
      featureTypes={featureTypes}
      headerText={headerText}
      plans={plans}
    />
  );
}

const pricePropType = PropTypes.shape({
  basePrice     : PropTypes.string,
  monthlyBilled : PropTypes.string
}).isRequired;

export const ComparisonChartDataPropsObject = {
  features : PropTypes.arrayOf(PropTypes.shape({
    featureType : PropTypes.shape({
      id : PropTypes.string.isRequired
    }).isRequired,
    id          : PropTypes.string.isRequired,
    title       : PropTypes.string.isRequired,
    tooltipText : PropTypes.string
  })).isRequired,
  featureTypes : PropTypes.arrayOf(PropTypes.shape({
    id    : PropTypes.string.isRequired,
    title : PropTypes.string.isRequired
  })).isRequired,
  hasMarginBottom : PropTypes.bool,
  headerText      : PropTypes.string,
  plans           : PropTypes.arrayOf(PropTypes.shape({
    annual       : pricePropType,
    categoryName : PropTypes.string.isRequired,
    ctaText      : PropTypes.string,
    displayName  : PropTypes.string.isRequired,
    monthly      : pricePropType,
    planFeatures : PropTypes.arrayOf(PropTypes.shape({
      description : PropTypes.string,
      feature     : PropTypes.shape({
        id : PropTypes.string.isRequired
      }).isRequired
    })).isRequired
  }))
};

ComparisonChartModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(ComparisonChartDataPropsObject).isRequired
};

ComparisonChartModule.defaultProps = {
  className : null
};

export const ComparisonChartModuleData = graphql`
  fragment ComparisonChartModuleData on ContentfulComponentComparisonChart {
    features {
      featureType {
        id
      }
      id
      title
      tooltipText
    }
    featureTypes {
      id
      title
    }
    hasMarginBottom
    headerText
    plans {
      categoryName
      ctaText
      displayName
      monthly {
        basePrice
        monthlyBilled
      }
      annual {
        basePrice
        monthlyBilled
      }
      planFeatures {
        description
        feature {
          id
        }
      }
    }
  }
`;
